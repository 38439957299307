
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    linkOrTo: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
