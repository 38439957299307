
import { defineComponent, type PropType } from 'vue';
import { type ResponsiveImageSet } from '@/types';

import TeaserCarouselItem from './TeaserCarouselItem.vue';
import TeaserCarouselSlider from './TeaserCarouselSlider.vue';

export type TeaserCarouselItemType = {
  category: string;
  color: string;
  id: string;
  image: ResponsiveImageSet;
  index: number;
  info: string;
  points: number | string;
  startsOn: Date | null;
  subtitle: string;
  title: string;
  url: string;
};

export default defineComponent({
  components: {
    TeaserCarouselItem,
    TeaserCarouselSlider,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    items: {
      type: Array as PropType<TeaserCarouselItemType[]>,
      required: true,
    },
  },
});
