import {
  fetchStories,
  fetchDoneStoriesIds,
} from '@/modules/stories/api/api.js';

import { type Story } from '@/modules/stories/types';

import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { useAuth } from '@/use/useAuth';

export const useStories = <T>(config: {
  normaliser(item: Story): T;
}): {
  unreadStories: ComputedRef<T[]>;
} => {
  const { headers } = useAuth();
  const { data: allStories } = useSWRV('overview-stories-all', () =>
    fetchStories(headers.value)
  );

  const { data: readStories } = useSWRV('overview-stories-read', () =>
    fetchDoneStoriesIds(headers.value)
  );

  const rawNormaliser = (x) => x;
  const unreadStories = computed(() => {
    return (allStories.value?.items || [])
      .filter((item) => !readStories.value.includes(item.id))
      .map(config.normaliser || rawNormaliser);
  });

  return {
    unreadStories,
  };
};
