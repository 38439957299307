import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { useAuth } from '@/use/useAuth';

import { fetchConsumer } from '../api/api';
import { AcademyConsumer, AcademyConsumerDTO } from '../types';

export const normaliseAcademyConsumer = (
  item: AcademyConsumerDTO
): AcademyConsumer => ({
  blockedForProgress: item.blocked_for_progress,
  consumerId: item.consumer_id,
});

export const useAcademyConsumer = (): {
  academyConsumer: ComputedRef<AcademyConsumer>;
} => {
  const { headers } = useAuth();

  const { data: academyConsumer } = useSWRV('consumer', () =>
    fetchConsumer(headers.value)
  );

  const normalisedAcademyConsumer = computed(() =>
    normaliseAcademyConsumer(academyConsumer.value)
  );

  return {
    academyConsumer: normalisedAcademyConsumer,
  };
};
