import useSWRV from 'swrv';
import { useRoute } from 'vue-router';
import { computed, ComputedRef } from 'vue';
import flatten from 'lodash/flatten';

import {
  postChallengeStart,
  fetchGroups,
  fetchGroupChallenges,
} from '../api/api';

import { Challenge, ChallengeDTO } from '../types';

import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';
import { useAuth } from '@/use/useAuth';
import { ROOT_URL } from '@/modules/academy/router';
import { useStore } from 'vuex';
import { useAcademyConsumer } from './academyConsumer';

const DYNAMIC_START_LINK = (type: string, slug: string): string => {
  return `${ROOT_URL}/challenge/${type}/${slug}/play`;
};

export const normaliseChallenge = (item: ChallengeDTO, username?: string): Challenge => {
  const replaceUsername = text => username ? text?.replace('{{USERNAME}}', username) : text;
  return {
    id: item.id,
    meta: item.meta,
    title: item.headline,
    description: item.content,
    label: item.label,
    labelColor: item.label_color,
    image: normaliseCmsImage({
      alt: item.headline,
      image: item.challenge_content?.value?.additional_ambassador_image?.url
      ? {
        image_mobile_1x: { url: item.challenge_content?.value?.additional_ambassador_image?.url },
        image_desktop_1x: { url: item.challenge_content?.value?.additional_ambassador_image?.url },
      }
      : item.main_image
    }),
    isIlumaJourney: item?.group?.title === 'Iluma Journey',
    points: Number(item.points),
    pointsType: item.points_type,
    category: item.challenge_title,
    slug: item.meta.slug,
    groupId: item?.group?.id,
    group: item?.group,
    link: `${ROOT_URL}/challenge/${item.meta.slug}`,
    startLink: DYNAMIC_START_LINK(item.challenge_content?.type || item.challenge_type, item.meta.slug),
    challengeContent: {
      type: item.challenge_content?.type || item.challenge_type || 'playable',
      uid: item.challenge_content?.id,
      gameId: item.challenge_content?.value?.playable_game_id,
      gameUrl: item.challenge_content?.value?.playable_game_url,
      audioUrl: item.challenge_content?.value?.podcast?.download_url,
      videoUrl: item.challenge_content?.value?.video?.download_url,
      name: item.headline,
      trackingAction: item.meta.slug,
      progress: {},
      welcomeHeadline: replaceUsername(item.challenge_content?.value?.welcome_headline),
      welcomeMessage: replaceUsername(item.challenge_content?.value?.welcome_message),
      wrongAnswerHeadline: replaceUsername(item.challenge_content?.value?.wrong_answer_headline),
      wrongAnswerMessage: replaceUsername(item.challenge_content?.value?.wrong_answer_message),
      duration: Number(item.challenge_content?.value?.duration),
      ctaName: item.challenge_content?.value?.cta_name || 'Challenge starten',
      noAmbassador: item.challenge_content?.value?.no_ambassador,
      ambassador: item.challenge_content?.value?.ambassador,
    },
    challengeDone: item.challenge_done,
  }
};

const FAKE_ILUMA_CHALLENGE_ID = 999999999;
const fakeIlumaChallenge = {
  id: FAKE_ILUMA_CHALLENGE_ID,
  title: 'Deine ILUMA Special Prämie wartet.',
  description: '',
  label: 'Special',
  labelColor: '#ffd040',
  image: {
    alt: 'Iluma Campaign prizes',
    desktop: {
      x1: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-xl@1x.jpg'),
      x2: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-xl@2x.jpg'),
      // x3: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-xl@3x.png'),
    },
    mobile: {
      x1: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-s@1x.jpg'),
      x2: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-s@2x.jpg'),
      // x3: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-s@3x.png'),
    },
  },
  isIlumaJourney: true,
  points: 300,
  pointsType: 'statuspoints',
  category: 'Iluma Journey',
  slug: 'iluma-group-gift',
  groupId: null,
  link: `${ROOT_URL}/iluma-group-gift`,
  startLink: `${ROOT_URL}/iluma-group-gift`,
  challengeContent: {
    type: '',
    name: 'Iluma Group Gift',
    trackingAction: 'iluma-group-gift',
    progress: {},
    welcomeHeadline: 'Gut gemacht',
    welcomeMessage: '<p data-block-key="q9q5h">Starte gleich Deine nächste Challenge und sichere Dir 10 IQOS Points: Es geht um die Vielfalt der TEREA Tabaksticks, die IQOS ILUMA für Dich bereithält. Los geht&#x27;s.</p>',
    wrongAnswerHeadline: 'Ups! Das war leider nicht richtig',
    wrongAnswerMessage: '<p data-block-key="tioqn">Versuch es gleich nochmal.</p>',
    duration: 0,
    ctaName: 'Prämie sichern',
    noAmbassador: false,
    ambassador: {}
  },
  challengeDone: false
}

type ChallengesReturnType = {
  challenges: ComputedRef<Challenge[]>,
  challengeFromUrl: ComputedRef<Challenge>,
  nextChallenge: ComputedRef<Challenge>,
  startPlayableChallenge: (challenge: Challenge) => Promise<void>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fakeIlumaChallenge: any,
  FAKE_ILUMA_CHALLENGE_ID: number,
}

export const useChallenges = (): ChallengesReturnType => {
  const { params } = useRoute();
  const { headers, consumer } = useAuth();
  const store = useStore();

  const groups = useSWRV('groups', () => fetchGroups(headers.value));

  const { academyConsumer } = useAcademyConsumer();

  const groupsWithChallenges = useSWRV('groups-with-challenges', () => Promise.all(
    groups.data?.value?.map(group => fetchGroupChallenges(group.id)(headers.value))
  ))

  const normalisedChallenges = computed(() => (
    groupsWithChallenges.data.value
    ? flatten(groupsWithChallenges.data.value).map(item => normaliseChallenge(item, consumer.value.firstName))
    : []
  ));

  const challengeFromUrl = computed(() => {
    return normalisedChallenges.value.find(
      ({ slug }) => slug === params.challenge
    );
  });

  const nextChallenge = computed(() => {
    const challengeList = normalisedChallenges.value.filter(item => !((item as Challenge)?.challengeDone))
    return challengeList[0];
  });

  const startPlayableChallenge = (challenge: Challenge) => {

    if (academyConsumer.value?.blockedForProgress)
      store.dispatch('academyModule/setIsChallengePlayWithFakeUser', true);

    return postChallengeStart(challenge.id)(headers.value)
      .then(() => {
        groups.mutate();
      })
      .catch((error) => {
        if (error?.response?.status === 402)
          store.dispatch('academyModule/setIsChallengePlayWithFakeUser', true);
      });
  };

  return {
    challenges: normalisedChallenges,
    challengeFromUrl,
    nextChallenge,
    startPlayableChallenge,
    fakeIlumaChallenge,
    FAKE_ILUMA_CHALLENGE_ID,
  };
};
