import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.external ? 'a' : 'router-link'), {
    id: _ctx.id,
    to: _ctx.external ? null : _ctx.linkOrTo,
    href: !_ctx.disabled && _ctx.external ? _ctx.linkOrTo : null,
    target: _ctx.external ? '_blank' : '_self',
    vBind: _ctx.$attrs,
    class: _normalizeClass(_ctx.disabled ? '' : 'cursor-pointer')
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["id", "to", "href", "target", "vBind", "class"]))
}