export default [
  {
    category: 'IQOS ILUMA Onboarding',
    id: '1',
    index: 0,
    points: 40,
    color: 'bg-primary-turquoise',
    subtitle: 'Challenge abschließen und Gratis TEREA Packung sichern.*',
    title: 'IQOS Club Academy',
    url: 'https://de.iqosclub.com/academy',
    image: {
      alt: 'IQOS rewardshop',
      desktop: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
    },
    info: '* Nur solange der Vorrat reicht.',
  },
  {
    category: 'Entdecke die Welt um IQOS',
    id: '2',
    index: 1,
    points: '30 Statuspunkte',
    color: 'bg-secondary-amber',
    subtitle: 'Neue Stories entdecken und \n Statuspunkte sichern.',
    title: 'IQOS CLUB Stories',
    url: 'https://de.iqosclub.com/stories',
    image: {
      alt: 'IQOS stories',
      desktop: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
    },
  },
  {
    category: 'Nur für kurze Zeit',
    id: '3',
    index: 2,
    points: 100,
    color: 'bg-primary-turquoise',
    subtitle: 'Sicher Dir pro Empfehlung 100 IQOS Points im Wert von 10€.',
    title: 'IQOS empfehlen**',
    url: 'https://www.iqos.com/de/de/freunde-empfehlen.html',
    image: {
      alt: 'IQOS empfehlen**',
      desktop: {
        x1: require('@/assets/shop/product-listing/fallback-teaser-empfehlen.png'),
        x2: require('@/assets/shop/product-listing/fallback-teaser-empfehlen@2x.png'),
        x3: require('@/assets/shop/product-listing/fallback-teaser-empfehlen@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/fallback-teaser-empfehlen.png'),
        x2: require('@/assets/shop/product-listing/fallback-teaser-empfehlen@2x.png'),
        x3: require('@/assets/shop/product-listing/fallback-teaser-empfehlen@3x.png'),
      },
    },
    info: '** Nur für erwachsene Raucher. Die Nutzung des Freundschaftscodes für kommerzielle Zwecke oder eine Verbreitung auf eBay ist untersagt und auf 50 erfolgreiche Empfehlungen limitiert. Nach abgeschlossenem Kauf mit Nutzung des Freundschaftscodes sowie erfolgreicher Registrierung Deiner Freunde erhältst Du 100 IQOS Points, die Du für Prämien im IQOS CLUB eintauschen kannst.',
  },
];
