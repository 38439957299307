import axios from 'axios';

const { VUE_APP_UNLEASH_API_TOKEN: UNLEASH_API_TOKEN } = process.env;

const featuresApi = axios.create({
  baseURL: 'https://config.iqosplus.de/api/frontend',
  headers: {
    'Content-type': 'application/json',
    // This token below will determine if we use the
    // development envirnoment or the production environment
    Authorization: UNLEASH_API_TOKEN,
  },
});

type Toggle = {
  name: string;
  enabled: boolean;
};

export const getFeatures = (
  config = null
): Promise<{ data: { toggles: Toggle[] } }> => featuresApi.get('/', config);
