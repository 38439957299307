import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';
import { useAuth } from '@/use/useAuth';

import { fetchCampaigns } from '../api/api';
import { Campaign, CampaignDTO } from '../types';

export const normaliseCampaign = (item: CampaignDTO): Campaign => ({
  id: item.id,
  title: item.title,
  bigHeadline: item.headline,
  smallHeadline: item.small_headline,
  text: item.text,
  label: item.label,
  labelColor: item.label_color,
  fontStyle: item.font_style,
  image: normaliseCmsImage({
    alt: item.image.title,
    image: item.image,
  }),
  link: {
    text: item.cta_name,
    href: item.cta_link,
  }
});

export const useCampaigns = (): { campaigns: ComputedRef<Campaign[]> } => {

  const { headers } = useAuth();

  const { data: campaigns } = useSWRV('campaigns', () => fetchCampaigns(headers.value));

  const normalisedCampaigns = computed(() => campaigns.value.map(normaliseCampaign));

  return {
    campaigns: normalisedCampaigns,
  };
};
