import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { getLoadedStateFromSwrvList } from '@/use/useComposable'

import { useAuth } from '@/use/useAuth';

import { fetchHomepageContent, fetchGroups } from '../api/api';
import { Campaign, Challenge, Group } from '../types';

import { normaliseCampaign } from './campaigns';
import { normaliseChallenge } from './challenges';

const mappersMap = {
  'academy2.AcademyChallengePage': normaliseChallenge,
  'academy2.CampaignPage': normaliseCampaign,
}

const normaliseChallengeOrCampaign = (item, username) => {
  const mapperFn = mappersMap[item?.meta?.type];
  return mapperFn ? mapperFn(item, username) : item;
}

export const useHomepage = (): {
  campaigns: ComputedRef<Campaign[]>,
  challenges: ComputedRef<Challenge[]>,
  firstChallenge: ComputedRef<Challenge>,
  unfilteredChallenges: ComputedRef<Challenge[]>,
  grid: ComputedRef<(Campaign | Challenge)[]>,
  ilumaChallenges: ComputedRef<Challenge[]>,
  ilumaGroup: ComputedRef<Group>,
  nextChallenge: ComputedRef<Challenge>,
  isLoaded: ComputedRef<boolean>,
} => {
  const { headers, consumer } = useAuth();
  const homepageContent = useSWRV('homepage-content', () => fetchHomepageContent(headers.value));
  const groupsContent = useSWRV('groups', () => fetchGroups(headers.value));

  const normalisedItems = computed(() =>
    (homepageContent.data.value || []).map(item => normaliseChallengeOrCampaign(item, consumer.value?.firstName))
  );
  const firstChallenge = computed(() => normalisedItems.value.find((item) => item.challengeContent));
  const campaigns = computed(() => normalisedItems.value.filter((item) => !item.challengeContent));

  const ilumaChallenges = computed(() =>
    normalisedItems.value.filter(item => item?.isIlumaJourney)
  )

  const ilumaGroup = computed(() =>
    (groupsContent.data.value || []).find(group => group.title === 'Iluma Journey')
  )

  const nextChallenge = computed(() => {
    const challengeList = normalisedItems.value.filter(item => (item as Challenge)?.challengeContent)
    return challengeList[0]
  })

  const challenges = computed(() => {
    return normalisedItems.value
      .filter(item => (item as Challenge)?.challengeContent)
      .filter(item => !item?.isIlumaJourney)
  })

  const unfilteredChallenges = computed(() => {
    return (normalisedItems.value || [])
      .filter(item => (item as Challenge)?.challengeContent)
  })

  const isLoaded = computed(() =>
    getLoadedStateFromSwrvList([
      homepageContent,
      groupsContent,
    ])
  )

  return {
    campaigns,
    challenges,
    firstChallenge,
    grid: normalisedItems,
    unfilteredChallenges,
    ilumaChallenges,
    ilumaGroup,
    isLoaded,
    nextChallenge,
  };
};
