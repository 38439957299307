import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eaa3190"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "teaser-carousel-slider overflow-hidden pb-16 md:pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeaserCarouselItem = _resolveComponent("TeaserCarouselItem")!
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_VueperSlides = _resolveComponent("VueperSlides")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueperSlides, {
      arrows: false,
      touchable: "",
      fixedHeight: _ctx.sliderHeight,
      draggingDistance: 30
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_VueperSlide, {
            key: item.id
          }, {
            content: _withCtx(() => [
              (_openBlock(), _createBlock(_component_TeaserCarouselItem, {
                key: item.id,
                title: item.title,
                image: item.image,
                points: item.points,
                category: item.category,
                subtitle: item.subtitle,
                info: item.info,
                color: item.color,
                url: item.url
              }, null, 8, ["title", "image", "points", "category", "subtitle", "info", "color", "url"]))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["fixedHeight"])
  ]))
}